<template>
  <div class="activity">
    <div class="header">活动</div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="activityBox" v-for="item in list" :key="item.id">
          <ImgDecypt class="activityCover" :src="item.coverImage" @click.native="jumpDetails(item)" />
          <div class="activityBottom flex-center-between">
            <div class="activityInfo" @click="jumpDetails(item)">
              <div class="activityName">{{ item.title }}</div>
              <div class="activityTime" v-if="item.effectiveCode != 1">活动时间：{{ item.startTime | timeYMD1 }}～{{ item.endTime | timeYMD1 }}</div>
              <div class="endActivity" v-else>活动已结束</div>
            </div>
            <div class="joinBtn" @click="join(item)" v-if="item.effectiveCode == 10">立即参与</div>
            <div class="joinBtn" @click="jumpDetails(item)" v-else>查看详情</div>
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>
<script>
import { queryActivityList } from '@/api/activity';
import { jumpAdv } from '@/utils/getConfig';
import PullRefresh from '@/components/PullRefresh';
import ImgDecypt from '@/components/ImgDecypt';

export default {
  name: 'Activity',
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryActivityList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    // 跳转详情
    jumpDetails(item) {
      this.$router.push({
        path: '/activityDetails',
        query: { id: item.id },
      });
    },
    join(item) {
      jumpAdv(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.activity {
  height: 100%;
  .header {
    color: rgb(24, 24, 24);
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    height: 44px;
    line-height: 44px;
  }
  .main {
    height: calc(100vh - 114px);
    padding: 10px 20px 0px;
    .activityBox {
      background-color: rgb(244, 244, 244);
      margin-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
      .activityCover {
        height: 139px;
        border-radius: 10px;
        overflow: hidden;
        transform: translateZ(0px);
      }
      .activityBottom {
        padding: 10px;
        .activityInfo {
          flex: 1;
          .activityName {
            font-size: 14px;
            color: rgb(65, 65, 65);
            font-weight: 500;
          }
          .activityTime {
            font-size: 10px;
            color: rgb(65, 65, 65);
          }
          .endActivity {
            color: rgb(255, 34, 34);
          }
        }
        .joinBtn {
          height: 28px;
          width: 78px;
          border-radius: 8px;
          background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
          line-height: 28px;
          text-align: center;
          color: white;
          letter-spacing: 0.19px;
        }
      }
    }
  }
}
</style>
